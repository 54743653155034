const timeConvertHelpers = {
  methods: {
    sec2time(sec) {
      return (
        ('00' + Math.floor(sec / 3600)).slice(-2) + ':' + ('00' + (Math.floor(sec / 60) % 60)).slice(-2) + ':' + ('00' + (Math.floor(sec / 1) % 60)).slice(-2)
      );
    },
    time2sec(time) {
      return parseInt(time.substring(6, 8)) + 60 * parseInt(time.substring(3, 5)) + 3600 * parseInt(time.substring(0, 2));
    },
    sec2timeArray(sec) {
      return [Math.floor(sec / 3600), Math.floor(sec / 60) % 60, Math.floor(sec / 1) % 60];
    },
    timeArray2sec(time) {
      return time[2] + 60 * time[1] + 3600 * time[0];
    },
  },
};

export default timeConvertHelpers;
